/* CustomNode.css */
.custom-node {
  /* border-radius: 5px; */
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background:rgb(242, 237, 237);
  font-family: sans-serif;
}

.custom-node-title {
  font-weight: bold;
  margin-bottom: 4px;
}

.custom-node-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
