@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&display=swap');
* {
  font-family: 'Manrope', 'europa', sans-serif;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-moz-selection {
  /* Code for Firefox */
  color: inherit;
  background: #161524;
}

::selection {
  color: inherit;
  background: #161524;
}

*::moz-selection {
  color: inherit;
  background: antiquewhite !important;
}

*::selection {
  color: inherit;
  background: #a0aec0 !important;
}

.swal-button {
  font-family: 'Europa', sans-serif;
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #e7bd5b;
  font-size: 16px;
  color: #ffffff;
}

/* .react-flow__node {
  cursor: pointer !important;
} */

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3) !important;
  color: #fff !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4) !important;
}

li > ol {
  padding-left: 25px;
}

li > ul {
  padding-left: 25px;
}

ol {
  padding-left: 25px !important;
}

.ck.ck-editor ul {
  padding-left: 25px !important;
}


.ghost_reorder {
  opacity: 1;
}
.chosen_reorder {
  /* color: transparent; */
  opacity: 0.001;
  /* background-color: transparent; */
}


.ck.ck-editor {
  width: 100% !important;
  max-width: 960px;
}

.editPageForm2.ck.ck-editor {
  width: 500px !important;
  max-width: 400px;
}
.ck-editor__editable_inline{
  min-height: 250px;
}
/* 

.ck.ck-editor {
  resize: both;
  overflow: auto !important;
  max-width: 100% ;
}
@media screen and (max-width:1280px){

  .ck.ck-editor {
    resize: both;
    overflow: auto;
    max-width: 500px ;
  
  }
}

@media screen and (max-width:700px){

  .ck.ck-editor {
    resize: both;
    overflow: auto;
    width: 100% ;
  
  }
}
*/
@media screen and (min-width:1100px){

  .ck.ck-editor {
    min-width: 960px ;
  
  }
  
.editPageForm2 .ck.ck-editor {
  width: 530px !important;
  max-width: 560px;
  min-width: 480px ;

}
} 
input[type='file'] {
  display: none;
}

/* App.css */
.highlight {
  background-color: yellow;
}




.table table {
  margin:20px 0;
  border-collapse: collapse;
}

.table table td, 
.table table th  {
  border: 1px solid black;
  padding: 10px;
  text-align: left;
}
.ck-content *{
  margin: 0 !important;;
}
.ck.ck-sticky-panel .ck-sticky-panel__content_sticky{
  z-index: 10!important;
}
.chakra-text img{
  width:100%
}
.modal-cke{
  width: 100%;
  background-color: #161524;
}
.sheet-editor{
  width: 100%;
  height: 500vh !important;
}
.css-3o5gsc{
  background-color: #161524;
  margin: left 10% !important;
}
.chakra-select{
  background-color: #161524;
  margin: left 10% !important;

  
}
.css-rm6vjl .css-1kii21t {
  background-color: #161524 !important;
  margin: left 10% !important;

}
.css-15m0d1a{
  overflow: hidden !important;
}

.css-1kii21t{
  background-color: #fff !important;
  
  margin: left 10% !important;
}
/* 
.fortune-cell-area{
  width: 100% !important;
}
.luckysheet-cell-flow-clip{
  width: 80% !important;
}
.fortune-sheet-container{
   
}
.fortune-container{
  width: 80% !important;
  overflow-x: hidden !important;
}
.luckysheet-cell-flow .luckysheetsheetchange{
   
  width: 100% !important;

}
.fortune-sheet-overlay{
  overflow: hidden !important;
} */

/* .ck-restricted-editing_mode_standard ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline */
/* .luckysheet-cell-flow .luckysheetsheetchange{
   
  width: 80% !important;

} */
/* .fortune-sheet-overlay{
  width:60% !important;
} */
/* .fortune-sheet-canvas{
  width: 80% !important;
}
.luckysheet-cell-sheettable{
  width: 80% !important;
} */

.sheet-editor1 .fortune-sheet-overlay{
  width:82.5vw !important;
}
.sheet-editor1 .fortune-sheet-canvas{
  width: 82.5vw !important;
}
.sheet-editor1 .fortune-sheet-container{
  width:82.5vw !important;
}

.sheet-editor2 .fortune-sheet-overlay{
  width:55vw !important;
}
.sheet-editor2 .fortune-sheet-canvas{
  width: 55vw !important;
}
.sheet-editor2 .fortune-sheet-container{
  width:55vw !important;
}
.ckparent .ck-editor__main{
  width: 55vw !important;
}
/* .ckparent .ck{
  width: 55vw !important;
} */
.ckparent .ck-toolbar__items{
  width: 55vw !important;
}
.ckparent .ck-editor__top {
  width: 55vw !important;
}
/* ck-reset_all
.ckparent .ck ck-editor__top {
  width: 55vw !important;
} */

.fortune-context-menu{
  position: fixed !important;
}